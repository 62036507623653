<ion-header>
  @if (!hide) {
    <!-- Left icon -->
    <div class="main-container app-content-max-width" [class.no-avatar]="!showAvatar" [class.mobile]="appService.isMobile$ | async">
      @if (showBackButton) {
        <img class="back-button" src="assets/icon/big-arrow-left.svg" alt="" (click)="onNavigateBack()" />
      } @else if ((appService.isMobile$ | async) === false) {
        <ion-menu-button data-cy="desktop-menu-btn"></ion-menu-button>
      }

      <!-- Center -->
      <div class="title-container" [class.mobile]="appService.isMobile$ | async" [routerLink]="'/'">
        @if (customTitle) {
          <div class="custom-title">{{ customTitle }}</div>
        } @else {
          <img src="assets/logo/wr-icon-outline.svg" alt="" />
          <div class="title">{{ 'PWA_banner_mobile_openApp_header' | translate }}</div>
        }
      </div>

      <!-- Right -->
      @if (showAvatar) {
        @if (userState.isGuest$ | async) {
          <div
            data-cy="sign-in-container"
            class="sign-in-container"
            [class.mobile]="appService.isMobile$ | async"
            (click)="onNavigateToAuthentication()">
            <div class="sign-in">{{ 'mainActions_CTA_signIn' | translate }}</div>
            <div class="person-img"></div>
          </div>
        } @else {
          <div class="avatar-container">
            @if (profileState.currentProfile$ | async; as profile) {
              <img
                (click)="onNavigateToProfile()"
                [src]="profile.isFamilyProfile ? 'assets/avatars/family-avatar.svg' : 'assets/avatars/' + profile.avatar"
                alt="" />
            } @else {
              <img src="assets/avatars/guest-user.svg" alt="" (click)="onNavigateToProfile()" />
            }
          </div>
        }
      }
    </div>
  }
</ion-header>
