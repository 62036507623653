import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ProfileState } from '../../store/profile.state';
import { AppService } from '../../services/app.service';
import { UserState } from '../../store/user.state';
import { IonHeader, IonMenuButton } from '@ionic/angular/standalone';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [IonHeader, AsyncPipe, TranslateModule, RouterLink, IonMenuButton],
})
export class HeaderComponent {
  @Input() hide = false;
  @Input() customTitle?: string;
  @Input() showAvatar: boolean = true;
  @Input() showBackButton: boolean = true;
  @Output() navigateBack = new EventEmitter<void>();

  constructor(
    public profileState: ProfileState,
    public userState: UserState,
    public appService: AppService,
    private router: Router,
  ) {}

  onNavigateBack(): void {
    this.navigateBack.emit();
  }

  onNavigateToProfile(): void {
    void this.router.navigate(['profile']);
  }

  onNavigateToHome(): void {
    void this.router.navigate(['home']);
  }

  onNavigateToAuthentication(): void {
    void this.router.navigate(['authentication']);
  }
}
